
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    emits: ['reload'],
    methods: {
        deleteItem(): void {
            this.delete(`product-categories/${this.$route.params.id}`)
                .then(() => {
                    this.$router.push({ name: 'products.categories.list' });
                    this.$emit('reload');
                });
        },
    },
});
